<template>
    <div class="picture" v-bind:style="{ background: background}"></div>
</template>

<script>
    export default {
        name: "employeepicture",
        data(){
            return{
            }
        },
        watch: {
            state: {
                immediate: true,
                handler(newVal, oldVal) {
                    for (let x in newVal) this[x] = newVal[x];
                }
            }
        },
        computed: {
            background(){
                if(this.pictureUrl) return 'var(--contrast-1) no-repeat center/cover url(' + this.pictureUrl + ')';
                let picture = 'url(../img/default.jpg)';
                picture = this.GetProfilePictureUrl(this.state);
                return picture;
            }
        },
        props:{
            state: Object,
            pictureUrl: String,
        },
        methods:{
            GetProfilePictureUrl(employee){
                if(!employee || !employee.employeePicture_server) return 'var(--contrast-1) no-repeat center/cover  url(../img/default.jpg)';
                return 'var(--contrast-1) no-repeat center/cover  url(' + employee.employeePicture_server + '/api/file?id='+ employee.employeePicture_fileId +'&accessToken='+employee.employeePicture.accessToken +"&thumb=small"+ '&time='+Date.now()+')';
            },
        }
    }
</script>

<style scoped>
.picture{
    height: 30px;
    width: 30px;
    border-radius: 100px;
}
</style>